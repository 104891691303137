"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartitionDateTimePattern = PartitionDateTimePattern;
var ecma402_abstract_1 = require("@formatjs/ecma402-abstract");
var FormatDateTimePattern_1 = require("./FormatDateTimePattern");
/**
 * https://tc39.es/ecma402/#sec-partitiondatetimepattern
 * @param dtf
 * @param x
 */
function PartitionDateTimePattern(dtf, x, implDetails) {
    x = (0, ecma402_abstract_1.TimeClip)(x);
    (0, ecma402_abstract_1.invariant)(!x.isNaN(), 'Invalid time', RangeError);
    /** IMPL START */
    var getInternalSlots = implDetails.getInternalSlots;
    var internalSlots = getInternalSlots(dtf);
    /** IMPL END */
    var pattern = internalSlots.pattern;
    return (0, FormatDateTimePattern_1.FormatDateTimePattern)(dtf, (0, ecma402_abstract_1.PartitionPattern)(pattern), x, implDetails);
}
